import {
  BoxProps,
  CheckFillSvg,
  color,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  Text,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import { ProBadgeSvg } from 'theme/icons/ProBadgeSvg';
import { DeepstashBenefit } from 'types/types';
import { PRO_COLOR } from 'utils/constants';
import { GAP_SIZE_REM, getRemWidthFromColumns } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';

const HEADING_TEXT_STYLE: StyleProps = {
  fontSize: spacing.toRem(20),
  lineHeight: '1.3em',
  textAlign: 'center',
};

interface ProComparisonTableDesktopProps extends BoxProps {
  benefits: DeepstashBenefit[];
}

// This table can be deployed to mobile devices, too.
// To do so, remove the commented section and keep the style as-is.

const ProComparisonTableDesktop: React.FC<ProComparisonTableDesktopProps> = ({
  benefits,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const SI_ONLY_BENEFIT_ROW = (
    <Flex
      w={{ base: '100%', md: 'auto' }}
      gridGap={{ base: 0, lg: GAP_SIZE_REM }}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Text
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
        fontWeight={900}
        textAlign="center"
        color={color[colorMode].textDisabled}
      >
        —
      </Text>
      <CheckFillSvg
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
        height={spacing.toRem(32)}
      />
      <CheckFillSvg
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
        height={spacing.toRem(32)}
        color={color[colorMode].pro.primary}
      />
    </Flex>
  );

  const PRO_ONLY_BENEFIT_ROW = (
    <Flex
      w={{ base: '100%', md: 'auto' }}
      gridGap={{ base: 0, lg: GAP_SIZE_REM }}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Text
        fontWeight={900}
        color={color[colorMode].textDisabled}
        textAlign="center"
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
      >
        —
      </Text>{' '}
      <Text
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
        fontWeight={900}
        textAlign="center"
        color={color[colorMode].textDisabled}
      >
        —
      </Text>
      <CheckFillSvg
        width={{ base: '25%', md: getRemWidthFromColumns(1.5) }}
        height={spacing.toRem(32)}
        color={color[colorMode].pro.primary}
      />
    </Flex>
  );

  return (
    <Flex
      width={{
        base: '100%',
        md: getRemWidthFromColumns(8),
        lg: getRemWidthFromColumns(10),
      }}
      flexDirection="column"
      gridGap={GAP_SIZE_REM}
      alignItems={{ base: 'center', md: 'end' }}
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        w={{ base: '100%', md: 'auto' }}
        gridGap={{ base: 0, md: GAP_SIZE_REM }}
      >
        <Flex flex={1} justifyContent="center" textAlign="center">
          <PrimaryText
            {...HEADING_TEXT_STYLE}
            textAlign="center"
            color={color[colorMode].textDisabled}
            width={{ base: '100%', md: getRemWidthFromColumns(1.5) }}
          >
            {commonStrings.without}
            <br />
            deep<b>stash</b>
          </PrimaryText>
        </Flex>
        <Flex flex={1} justifyContent="center" textAlign="center">
          <PrimaryText
            {...HEADING_TEXT_STYLE}
            textAlign="center"
            color={color[colorMode].text}
            width={{ base: '100%', md: getRemWidthFromColumns(1.5) }}
          >
            {commonStrings.with}
            <br />
            deep<b>stash</b>
          </PrimaryText>
        </Flex>
        <Flex flex={1} flexDirection="column" alignItems="center">
          <PrimaryText
            {...HEADING_TEXT_STYLE}
            textAlign="center"
            color={PRO_COLOR[colorMode]}
            width={{ base: '100%', md: getRemWidthFromColumns(1.5) }}
          >
            {commonStrings.with}
          </PrimaryText>
          <Flex alignItems={'center'} gridGap={spacing.toRem(4)}>
            <PrimaryText {...HEADING_TEXT_STYLE} color={PRO_COLOR[colorMode]}>
              deep<b>stash</b>
            </PrimaryText>
            <ProBadgeSvg height={20} />
          </Flex>
        </Flex>
      </Flex>
      {benefits.map((benefit, index) => (
        <>
          {/* {isMobileView && (
            <Box
              w="100%"
              height={spacing.toRem(2)}
              backgroundColor={color[colorMode].underground}
            />
          )} */}
          <Flex
            alignItems="center"
            gridGap={{ base: spacing.M.rem, md: 0 }}
            key={index}
            w="100%"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Flex
              flexGrow={{ base: 0, md: 1 }}
              alignItems="center"
              gridGap={spacing.toRem(16)}
            >
              {benefit.icon}
              {benefit.name}
            </Flex>
            {benefit.accessLevel === 'si'
              ? SI_ONLY_BENEFIT_ROW
              : PRO_ONLY_BENEFIT_ROW}
          </Flex>
        </>
      ))}
    </Flex>
  );
};

export default ProComparisonTableDesktop;
